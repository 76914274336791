<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="2"><a-button v-if="isShow" v-has="'mail:send'" @click="sendmail"
												type="primary">发送邮件</a-button></a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<div v-if="isShow">
			<a-table :columns="columns" :pagination="false" :scroll="{ x: '100%' }" row-key="id" :indentSize="25"
							 :defaultExpandAllRows="true" size="small" :style="{ background: '#FFF' }" :data-source="data.records"
							 border>
				<!-- 阅读状态 -->
				<template slot-scope="row" slot="tit">
					<a @click="enter(row)">{{ row.title }}</a>
				</template>
				<!-- 操作 -->
				<template slot-scope="row" slot="action">
					<a-tooltip title="删除">
						<a-button type="link" v-has="'mail:receive:del'" icon="delete" @click="del(row.id)"></a-button>
					</a-tooltip>
				</template>
			</a-table>
			<a-row>
				<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
					<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
												:current="data.current" />
				</a-col>
			</a-row>
		</div>

		<postmail v-else @success="onsubmit" :rowMail="rowMail"></postmail>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import { Icon } from 'ant-design-vue';
import postmail from './components/postmail.vue';
const MyIcon = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/c/font_3394332_2wzi7hji7im.js' // 在 iconfont.cn 上生成
});
export default {
	components: { 'my-icon': MyIcon, postmail },
	data() {
		return {
			isShow: true,
			mailId: '',
			columns: [
				{ title: '是否发送', dataIndex: 'status', align: 'center', width: 100 },
				{ title: '主题', scopedSlots: { customRender: 'tit' }, width: 200 },
				{ title: '收件人', dataIndex: 'receiveNames', align: 'center', width: 200, ellipsis: true },
				{ title: '时间', dataIndex: 'createTime', align: 'center', width: 160 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			],
			rowMail: {},
			data: {},
			param: {
				page: 1,
				limit: 20,
				keyword: ''
			}
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		...apiUtil,
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/mail/send/draft-list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		sendmail() {
			this.rowMail = {};
			this.isShow = false;
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 点击返回
		black() {
			this.isShow = true;
			this.getList();
		},

		// 进入编辑页面
		enter(row) {
			utils.showSpin();
			this.mailId = row.id;
			this.rowMail = row;
			this.isShow = false;
			utils.closeSpin();
		},

		// 删除发送的邮件
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/mail/send/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.isShow = true;
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},
		onsubmit() {
			this.isShow = true;
			this.getList();
		}
	}
};
</script>

<style scoped lang="less">
.title {
	font-size: 30px;
}

.mailtitle {
	font-size: 28px;
}

.content {
	margin-bottom: 20px;
}

.container {
	display: flex;
	text-align: center;
}
</style>
